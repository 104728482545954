import interactsWithProjectPortal from '@js/client-portal/vue-mixins/interactsWithProjectPortal.js';
import updatesUpdatedAt from '@js/nal/vue-mixins/updatesUpdatedAt.js';

export default {
    mixins: [interactsWithProjectPortal, updatesUpdatedAt],
    computed: {
        projects() {
            return this.nal.projects || [];
        },
        hasProjects() {
            return !!this.projects.length;
        },
        projectId() {
            const id = + this.$route.params.id;
            return this.isValidProjectId(id) ? id : null;
        },
        _project() {
            return this.projectId && this.getProject(this.projectId) || null;
        },
        projectName() {
            return this._project?.title || '';
        },
        _portal() {
            return this._project?.portal || null;
        },

        _projectType() {
            return this._project?.label || '';
        },

        // common for cp and search
        _model: {
            get() {
                return this._rootParent && this._rootParent.model || this._project;
            },
            set() {
                return;
            },
        },
        _dataType() {
            return this._rootParent && this._rootParent.dataType || this._projectType;
        },
        _rootParent() {
            let parent = this;
            while (!this._hasModel(parent) && parent.$parent) {
                parent = parent.$parent;
            }
            if (this._hasModel(parent)) {
                return parent;
            }
            return null;
        },
        canEdit() {
            return this.auth('edit', this._dataType, this._model);
        },
        canCreate() {
            return window.auth('create', this._dataType);
        },
        canEditOrCreate() {
            return this.canEdit || this.canCreate;
        },
    },
    methods: {
        isValidProjectId(id) {
            return id && this.hasProjects && this.projects.some(project => project.brief_id === id);
        },
        getProject(projectId) {
            return this.projects.find(project => project.brief_id === projectId);
        },
        updateProjectData(projectId, path, data) {
            const project = this.getProject(projectId) || null;
            if (project) {
                const target = _.get(project, path);
                if (target !== undefined) {
                    _.set(project, path, data);
                }
            }
        },
        // common for cp and search
        _hasModel(instance) {
            return typeof instance.$data.model !== 'undefined'
                || typeof instance.$props.model !== 'undefined';
        },
        _url(url) {
            return `/portal/${this._portal?.id}/${url}`;
        },
        auth(action, type, obj) {
            if (obj && type) {
                return window.authorize(action, type, obj, { model: this._project, type: this._projectType });
            }
            let modelType = this._dataType === 'leads' ? 'contacts' : this._dataType;
            return !_(this._model).get('id') || window.authorize(action, modelType, this._model);
        },
    },
};