export default {
    computed: {
        projects() {
            return this.nal.projects || [];
        },
    },
    methods: {
        getProject(projectId) {
            return this.projects.find(project => project.brief_id === projectId);
        },
        updateProjectPortal(projectId, data, key = false) {
            const project = this.getProject(projectId) || {};
            if (!project.portal) {
                return false;
            }
            if (key) {
                project.portal[key] = data;
            } else {
                Object.assign(project.portal, data);
            }
        },
    },
};